import { useState, useEffect } from 'react';
import { getAllPaginatedData } from '../config/api/methodsByApi';
const useSelectOptions = (url, dataKey, param) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAllPaginatedData(url, dataKey, setOptions, param);
    setLoading(false);
  }, [url]);

  return { options, loading };
};

export default useSelectOptions;
